@use "variables";
@use "mixins";
@use "common";

.user-login{
	height: 100%;
	width: 100%;
    .block{
    	display: flex;
    	@include mixins.tablet {
    		flex-wrap: wrap;
    	}
    	.image{
	        flex-basis: 100%;
			margin-right: 40px;
	        @include mixins.tablet {
	        	flex-grow : 1;
	        	text-align: center;
	        	margin-bottom: 30px;
	        	margin-right: 0;
	        }
	        img{
	        	border-radius: 8px;
	        	@extend .shadow;
	        }
    	}
    	.content{
			flex-basis: 100%;
            align-self: center;
    	    padding: 50px;
    	    border: 1px solid variables.$border-color;
    	    border-radius: 4px;

    		@include mixins.tablet {
	        	flex-grow : 1;
	        }
    	    .logo{
    			margin-bottom: 80px;
    			@include mixins.desktop {
    				margin-bottom: 40px;
    			}
    		}
    		.title-text{
    			margin-bottom: 35px;
    			h3{
    				padding-bottom: 20px;
    				border-bottom: 1px solid variables.$border-color;
    			}
    		}
    		.new-acount{
    			margin-top: 20px;
    			p,a{
    				font-size: 14px;
    			}
                p{
                    a{
                        color: variables.$black;
                    }
                }
    		}
    	}
    }
}