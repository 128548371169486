@use "variables";
@use "common";

.founder{
	margin-bottom: 30px;
	img{
        border-radius: 5px;
	    margin-bottom: 25px;
	    @extend .shadow;
	}
	h2{
		font-size: 30px;
		line-height: 30px;
	}
	cite{
		font-size: 14px;
		font-style: normal;
	}
	p{
		margin-top: 10px;
		font-size: 14px;
		margin-bottom: 20px;
	}
}

.team-sm{
	margin-bottom: 30px;
	.image{
		position: relative;
		overflow: hidden;
		margin-bottom: 30px;
		img{
			border-radius: 5px;
			@extend .shadow;
		}
		.social-links{
			position: absolute;
			background: variables.$primary-color;
			left: 0;
			right: 0;
			text-align: center;
			width: calc(100% - 80px);
			margin: 0 40px;
	        border-radius: 4px;
	        opacity: 0;
	        transform: translate3d(0,10px,0);
	        transition: 0.3s;
			bottom: 20px;
			ul{
				margin-bottom: 0;
				li{
					a{
						display: block;
						padding: 15px;
						i{
							font-size: 20px;
							color: variables.$light;
						}
					}
				}
			}
		}
		&:hover{
			.social-links{
				opacity: 1;
          		transform: translate3d(0,0,0);	
			}
		}
	}
	h3{
		margin-bottom: 0;
	}
	cite{
		font-size: 14px;
		font-style: normal;
	}
	p{
		margin-top: 15px;
	}
}