@use "variables";
@use "mixins";

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mixins.tablet {
    flex-direction: column;
    align-items: center;
  }
}

.pricing .row .col {
  flex: 1 1 0%;
  min-width: 0;
  max-width: 20%;

  @include mixins.tablet {
    flex: 0 0 100%;
    max-width: 90%;
    margin-bottom: 1.5rem;
  }
}

.pricing .pricing-card {
  height: 100%;
  padding: 1rem;
  background: variables.$light;
  border-radius: 1rem;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 1px 3px variables.$secondary-color;
  font-size: clamp(0.8rem, 1.1vw, 1rem);

  @include mixins.tablet {
    padding: 1rem;
    font-size: 14px;
  }

  h3 {
    font-size: clamp(1rem, 1.5vw, 1.6rem);
    margin-bottom: 1rem;
    font-weight: bold;
  }

  h4 {
    font-size: clamp(0.8rem, 1.3vw, 1.1rem);
    margin-bottom: 1rem;
  }

  .price {
    margin-bottom: 1.2rem;

    .currency {
      font-size: clamp(0.8rem, 1.2vw, 1.2rem);
      font-weight: 600;
      vertical-align: top;
      line-height: 1;
    }

    .amount {
      font-size: clamp(1.8rem, 3vw, 2.5rem);
      font-weight: 700;
      line-height: 1;
    }

    .period {
      font-size: clamp(0.8rem, 1vw, 1rem);
    }
  }

  .description {
    margin-bottom: 1rem;
    font-size: clamp(0.75rem, 1vw, 0.9rem);
  }

  .features-list {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem 0;

    li {
      display: flex;
      font-size: clamp(0.7rem, 0.9vw, 0.85rem);
      margin-bottom: 0.5rem;

      i {
        color: variables.$primary-color;
        margin-right: 0.7rem;
        font-size: 0.9rem;
        margin-top: 0.4rem;
      }
    }
  }

  .btn {
    width: 100%;
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    border-radius: 50px;
    font-size: clamp(0.75rem, 1vw, 1rem);

    &.btn-primary {
      background: variables.$light;
      border: 2px solid variables.$secondary-color;
      color: variables.$secondary-color;

      &:hover {
        background: variables.$primary-color;
        border: 2px solid variables.$primary-color;
        color: variables.$light;
      }
    }
  }
}

.pricing .pricing-card:hover {
  box-shadow: 0 10px 30px variables.$secondary-color;
}

.pricing .pricing-card.popular {
  outline: 5px solid variables.$primary-color;
  outline-offset: -5px;

  h3,
  h4,
  .price .currency,
  .price .amount,
  .price .period,
  .description,
  .features-list li {
    color: variables.$secondary-color;
    font-weight: bold;
  }

  .btn-light {
    background: variables.$secondary-color;
    border: 2px solid variables.$secondary-color;
    color: variables.$light;
    font-weight: bold;

    &:hover {
      background: variables.$primary-color;
      border: 2px solid variables.$primary-color;
    }
  }
}

.pricing .pricing-card .popular-badge {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: variables.$primary-color;
  color: variables.$light;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: clamp(0.6rem, 0.9vw, 0.8rem);
  font-weight: bold;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);

  @include mixins.tablet {
    padding: 0.3rem 0.75rem;
    top: -15px;
  }
}
