@use "variables";
@use "mixins";

.address {
	.block {
		.address-block {
			text-align: center;

			.icon {
				margin-bottom: 25px;
				display: flex;
				justify-content: center;

				i {
					display: block;
					height: 100px;
					width: 100px;
					background: variables.$light-gray;
					border-radius: 100%;
					font-size: 45px;
					text-align: center;
					line-height: 100px;
				}
			}
		}
	}
}

.google-map {
	position: relative;

	#map_canvas {
		height: 500px;
		width: 100%;
		@include mixins.tablet {
			height: 350px;
		}
	}
}