@use "variables";
@use "mixins";

.btn {
  text-transform: uppercase;
}

.btn-download {
  border-radius: 50%;
  padding: 20px 20px;
  font-size: 16px;
  background: variables.$light;
  color: variables.$primary-color;
	cursor: pointer;
  transition: 0.3s;

  span {
    margin-left: 5px;
    font-size: 20px;
  }
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: variables.$primary-color;
  color: variables.$light;
  outline: none;

  &:hover {
    color: variables.$light;
  }

  &:focus {
    color: variables.$light;
    box-shadow: none;
  }
}

.btn-main-md {
  padding: 17px 38px;
  border-radius: 3px;
  background: variables.$primary-color;
  color: variables.$light;
  outline: none;

  &:hover {
    color: variables.$light;
  }

  &:focus {
    color: variables.$light;
    box-shadow: none;
  }
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: variables.$primary-color;
  color: variables.$light;
  outline: none;
  font-size: 14px;

  &:hover {
    color: variables.$light;
  }

  &:focus {
    color: variables.$light;
    box-shadow: none;
  }
}

.btn-white {
  background: white;
  color: variables.$primary-color;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: variables.$light;
  border: 1px solid variables.$light;
  padding: 13px 50px;
  transition: 0.3s;
	cursor: pointer;
	&:hover {
		background: variables.$secondary-color;
    color: variables.$primary-color;
	}
}

.btn-whatsapp {
  display: inline-block;
  background-color: #25D366; /* WhatsApp Green */
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 13px 50px;
  border-radius: 100px;
  text-decoration: none;
  transition: 0.3s;
	cursor: pointer;
	&:hover {
		background: #1ebe57;
	}
	&:focus {
    background-color: #25D366; /* Original WhatsApp Green */
    outline: none;
	}
}

.btn-whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0px 10px;
  border-radius: 50%;
  font-size: 28px;
  text-align: center;
  background-color: #25D366;
  color: variables.$light;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  z-index: 999555;
	cursor: pointer;
	&:hover {
		background: #1ebe57;
	}
	&:focus {
    background-color: #25D366; /* Original WhatsApp Green */
    outline: none;
	}

	@include mixins.tablet {
		bottom: 15px;
		right: 15px;
		padding: 0px 7px;
		font-size: 22px;
	}
}